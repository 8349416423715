const push = (hash: string): void => {
    window.history.pushState(null, document.title, hash);
    /*
    const allLinks = document.querySelectorAll(
        '.header__menu a, .header__menu-mobile a, .footer__menu a, a.footer__logo, a.header__logo'
    );
    allLinks.forEach(item => item.classList.remove('active'));
    document.querySelectorAll(`[href="${hash}"]`).forEach(item => item.classList.add('active'));
    */
};

document
    .querySelectorAll(
        '.header__menu a, .header__menu-mobile a, .footer__menu a, a.footer__logo, a.header__logo'
    )
    .forEach(t => {
        t.addEventListener('click', (e): void => {
            if (t.classList.contains('mailto')) return;
            e.preventDefault();
            const nameEl: string | null = t.getAttribute('href');
            if (!nameEl) return;
            const element = document.querySelector<HTMLDivElement>(nameEl);
            if (element) {
                window.scrollBy({
                    top:
                        element.offsetTop - window.pageYOffset - (window.innerWidth < 780 ? 80 : 0),
                    left: 0,
                    behavior: 'smooth'
                });
            }
            push(nameEl);
        });
    });

export default null;
