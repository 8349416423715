import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { detect } from 'detect-browser';

interface Country {
    name: string;
    title: string;
}

const db = detect()?.name;
const isNotMc = db !== 'edge' && db !== 'ie';

const WorldMap: React.FC = () => {
    const [currentCountry, setCurrentCountry] = useState<string | null>(null);
    const [currentPlacePosition, setCurrentPlacePosition] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0
    });

    useEffect((): void => {
        switch (currentCountry) {
            case 'Spain':
                setCurrentPlacePosition({ x: 80, y: 70 });
                break;
            case 'Portugal':
                setCurrentPlacePosition({ x: 12, y: 60 });
                break;
            case 'Italy':
                setCurrentPlacePosition({ x: 300, y: 70 });
                break;
            case 'Greece':
                setCurrentPlacePosition({ x: 425, y: 120 });
                break;
            case 'Cyprus':
                setCurrentPlacePosition({ x: 535, y: 120 });
                break;
            case 'Balkans':
                setCurrentPlacePosition({ x: 460, y: 20 });
                break;
            default:
                setCurrentPlacePosition({ x: -1000, y: -1000 });
                break;
        }
    }, [currentCountry]);

    return (
        <>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 -20 590 300"
                xmlSpace="preserve"
            >
                <defs>
                    <filter id="filter-blur" x="0" y="0" width="200%" height="200%">
                        <feDropShadow
                            dx="5"
                            dy="10"
                            stdDeviation="3"
                            floodColor="black"
                            floodOpacity=".7"
                        />
                    </filter>
                </defs>
                <path
                    id="Balkans"
                    filter={isNotMc && currentCountry === 'Balkans' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Balkans' })}
                    onClick={(): void => setCurrentCountry('Balkans')}
                    d="M538.2,47.5c-0.1-0.2-0.3-0.5-0.4-0.7c-1.4-2.2-3.1-1.5-6.9,0.7c-3.8,2.2-7.1,2.1-9.6-0.3l0,0
        c-0.8-0.8-1.5-1.8-2-2.8c-2.1-4-1.7-7.4-1.2-11.1c0.1-0.6,0.2-1.2,0.2-1.8c0.2-2.1-0.6-3.7-1.6-5.5c-0.9-1.5-1.6-3.2-1.9-4.9
        c-0.4-2.7-0.7-2.9-2.6-3.8c-0.5-0.3-1.2-0.6-1.9-1c-3.2-1.8-5.8-4.6-7.2-8c-1.3-3.1-4.3-7.1-8.4-8.1c-0.4-0.1-0.8-0.2-1.2-0.2
        c-1.9,0-3.3,1.3-4.9,2.8c-0.4,0.3-0.8,0.7-1.2,1c-2.9,2.5-6.3,4.4-9.9,5.6c-2.3,0.8-4.8,0.5-6.8-0.8c-0.9-0.5-1.9-0.9-2.9-1.2
        c-1.6-0.3-3.2-0.3-4.8-0.2c-1.4,0.1-2.8,0.1-4.2,0c-1.5-0.2-3.3-0.2-4.9-0.2c-1.1,0-2.2,0-3.3-0.1c-0.2,0-0.5,0-0.8-0.1
        c-0.9-0.1-1.8-0.1-2.7,0c-0.2,0.1-0.4,0.1-0.5,0.2l-0.2,0.1c0,0,0,0.1,0,0.1c-0.2,0.4-0.4,0.8-0.7,1.2c-1.1,1.4-2.5,2.4-4.1,3
        c-2.3,0.7-4,2.7-4.4,5.1c-0.4,1.5-1.1,3-2.1,4.2c-0.4,0.4-0.7,0.7-1.2,1c-0.4,0.3-0.8,0.7-1,1.1c-0.2,0.6-0.3,1.3-0.2,1.9
        c0,0.6,0,1.2-0.1,1.8c-0.4,2.8-2,4.4-3.5,5.9c-0.7,0.7-1.3,1.4-1.9,2.1c-2.6,3.5-4.6,4.8-8.8,6c-0.3,0.1-0.6,0.2-0.9,0.3
        c1.5,1.7,2.8,3.5,3.9,5.4c0.4,0.7,0.7,1.3,1,2c0.3,0.9,0.8,1.7,1.3,2.4c0.7,0.7,1.6,1.2,2.5,1.5c0.5,0.2,0.9,0.4,1.4,0.6
        c1.6,0.8,3.4,1.8,4.2,3.2c0.6,1.1,1,2.4,1.1,3.7c0.1,0.7,0.3,1.4,0.5,2.1c0.7,1.9,1.2,2,3.4,2.6l0.2,0.1c0.7,0.2,1.4,0.5,2,0.8
        c1,0.6,2.1,0.8,3.2,0.7c0.6-0.2,1.1-0.4,1.6-0.8c1.2-0.7,2.8-1.6,4.7-0.9c3.2,1.1,2.5,4.6,2.2,6.1c-0.1,0.3-0.2,0.6-0.2,1
        c1,0.2,1.9,0.7,2.6,1.4c0.8,0.9,1.1,2.1,1,3.3c-0.1,0.6,0,1.1,0.3,1.6c0.4,0.4,2.6,0.2,3.5,0.2c0.5,0,1-0.1,1.3-0.1
        c2.5,0.1,5,0.4,7.5,1c5,1.1,9.5,1.1,14.8,1.1h0.6c2.7,0,5.2,0,7.4-1.2c1.1-0.6,2.1-1.6,2.6-2.8c0.5-0.8,1-1.6,1.6-2.4
        c1.3-1.5,3.3-2,5-2.4c0.8-0.2,1.5-0.4,2.3-0.7c2.5-1.2,5.2-1.9,8-1.9c2.2,0.1,4.3,0.6,6.3,1.5c1.3,0.5,2.7,1,4.1,1.3
        c0.7,0.1,1.3,0.2,2,0.3c1.1,0.1,2.3,0.3,3.4,0.5c0.5,0.1,1,0.2,1.6,0.2c0.3,0,0.7,0,1,0.1c0-0.1,0-0.1,0-0.2c0.1-0.8,0.1-1.6,0-2.4
        c-0.1-1.5,0.1-3,0.4-4.5c0.2-0.8,0.5-1.5,0.7-2.1c0.6-1.3,0.9-2.8,0.8-4.3c-0.1-0.7-0.1-1.4-0.2-2.1c-0.2-1.2-0.3-2.5-0.3-3.7l0.1-1
        l1-0.1c1.3-0.1,2.6,0.1,3.8,0.8c0.5,0.3,1.1,0.5,1.7,0.6c1.4,0.3,2.4,0.1,2.9-0.6C539.3,51.8,539.2,49.5,538.2,47.5z M528.9,80.4
        c0.8-0.4,1.3-1.3,1.2-2.2c0.1-1.2-0.5-2.4-1.6-3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.6-0.1-1.2-0.2-1.8-0.2c-0.6,0-1.3-0.1-1.9-0.2
        c-1-0.2-2.1-0.3-3.2-0.5c-0.7-0.1-1.4-0.2-2.1-0.3c-1.6-0.3-3.1-0.8-4.5-1.4c-4-1.9-8.7-1.8-12.6,0.3c-0.9,0.3-1.7,0.6-2.6,0.8
        c-1.5,0.4-3,0.8-3.8,1.7c-0.5,0.6-1,1.3-1.4,2c-0.8,1.6-2,2.9-3.6,3.7c-2.8,1.4-5.7,1.5-8.5,1.4h-0.6c-5.2,0-10.1,0-15.4-1.2
        c-2.3-0.6-4.6-0.9-7-1c-0.3,0-0.7,0-1.2,0.1c-1.9,0.1-4.2,0.3-5.4-1c-0.7-0.9-1.1-2.1-0.9-3.3c0.1-0.5,0-1.1-0.3-1.6
        c-0.4-0.4-1-0.7-1.5-0.8c-0.3,1.1-0.8,2.2-1.2,3.3c-0.2,0.5-0.4,1-0.6,1.5c-0.7,1.6-1.3,3-0.7,4.3v0c0.8,1.3,1.9,2.5,3.2,3.3
        c1.1,0.8,2.1,1.7,3,2.7c1.7,2.2,0.9,6.8-0.4,8.9c-0.5,0.8-1.2,1.6-1.9,2.2c-0.8,0.6-1.4,1.4-1.7,2.3c-0.4,1.2-0.6,2.4-0.6,3.6
        c0,0.5-0.1,1-0.1,1.5c0.5,0.5,1,1,1.3,1.6l0.1,0.1c0.2,0.5,0.7,0.9,1.2,1c1.1,0.4,2.1,0.9,3.1,1.7c2.1,1.4,3.3,3.8,3.1,6.3
        c0,0.4-0.1,0.9-0.2,1.3h0.2c0.7,0,1.4,0,2.1,0c1.3,0.2,2.6,0.7,3.7,1.4c0.6,0.3,1.2,0.7,1.8,0.9c0.6,0.2,1.2-0.1,2.2-0.7
        c0.5-0.3,1-0.6,1.5-0.8c0.4-0.2,0.8-0.3,1.3-0.4c0.3-0.1,0.6-0.1,0.8-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.5,0.7-0.9,1.1-1.2
        c1.9-1.2,6.3-0.4,8,1c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.1,0.6,0.1,0.9,0c0.3-0.1,0.5-0.1,0.8-0.1
        c0.2,0,0.4,0,0.7,0c1.2,0.1,2.4,0.5,3.3,1.3c0.4,0.3,0.7,0.5,1.2,0.8c2,1,4.9,0,7.3-0.8l0.7-0.2c1-0.4,2.1-0.6,3.2-0.6
        c0.1,0,0.2,0,0.3,0v-0.1c0-0.2-0.2-0.8-0.4-1.3c-0.7-2.1-1.1-3.6-0.6-4.7c0.2-0.4,0.6-0.7,1.1-0.9c0.6-0.1,1.3,0,1.8,0.4
        c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.2-0.4,0.4-0.8,0.7-1.1c0.2-0.3,0.6-0.6,0.9-0.8c0.1-0.1,0.3-0.2,0.4-0.3
        c0-0.1,0.1-0.3,0.1-0.4c0.1-0.4,0.2-0.8,0.4-1.1c0.7-1.2,2-2,3.4-2l0.8-0.1c0.5,0,0.9-0.2,1.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5
        c0.2-0.5,0.6-1,1-1.3c1.3-1,2.9-0.6,4.2-0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0.1,0.8,0.2,1.4,0.3c1.5,0.4,6.1,1.6,6.8,0.4l0.1-0.2
        c0.2-0.9-1.8-2.7-3.8-3.8c-3.3-1.8-4.8-4.3-4-6.7c0.5-1.1,1.3-2.1,2.3-2.7c0.4-0.3,0.7-0.5,1-0.8c1.5-1.4,2.4-3.5,2.3-5.6l0-0.5
        c0-1.6,0-3.9,1.9-5c0.9-0.4,1.8-0.6,2.8-0.5C527.7,80.7,528.3,80.6,528.9,80.4z M423.2,118c0,0.2-0.1,0.3-0.1,0.5
        c-0.1,0.5-0.2,1-0.3,1.6c-0.6,2.6-1.1,4.9,0.1,7.2c0.4,0.8,0.7,1.6,0.9,2.5c0.1,0.6,0.3,1.3,0.6,1.8c0.2,0.4,0.5,0.5,1.4,0.5
        c0.6,0,1.2,0.2,1.8,0.4c0.4,0.1,0.8,0.3,1.1,0.6c0.6-0.2,1.3-0.4,1.9-0.5c0.6-0.1,1.1-0.4,1.5-0.8c0.5-0.4,1.1-0.7,1.8-1
        c0.7-0.2,1.3-0.3,2-0.3c0.5,0,0.9-0.1,1.4-0.2c0.7-0.3,1.3-0.7,1.8-1.2c0.4-0.3,0.8-0.7,1.2-0.9l0.2-0.1c1.2-1,2.7-1.5,4.2-1.3
        c0.4,0.1,0.9,0.2,1.3,0.2c0.7,0.2,1.4,0.3,2.2,0.3c4-0.1,4.5-1,4.6-1.8c0.1-1.5,0.5-2.9,1.3-4.2c0.2-0.4,0.4-0.8,0.6-1.2
        c0.3-0.7,0.5-1.4,0.5-2.2c0.1-1.6-0.6-3.2-2-4.1c-0.8-0.6-1.6-1.1-2.5-1.4c-1.1-0.2-2-1-2.5-2c-0.4-0.6-0.9-1.2-1.4-1.6
        c-0.8-0.6-1.8-1-2.8-0.9h-0.2c-1.5-0.1-3,0.1-4.4,0.6l0,0c-0.2,0.1-0.5,0.2-0.7,0.4c-0.7,0.5-1.5,0.8-2.4,0.7c-0.1,0-0.2,0-0.4,0
        c-0.7,1.5-1.6,2.9-3.3,3.2c-1,0.2-2,0.1-3-0.1c-1.7-0.3-2.4-0.3-3.1,0.6c-0.3,0.4-0.5,0.9-0.6,1.4c-0.2,0.8-0.5,1.5-0.9,2.1
        C424.5,117.2,423.9,117.7,423.2,118z M380.7,97.7c1.3,1.6,2.8,2.9,4.5,3.9c0.2,0.1,0.6,0.3,0.9,0.6c0.1,0.1,0.2,0.2,0.4,0.2
        c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.5-0.4-0.9-0.6-1.3c-1.1-1.8-2.8-2.9-4.7-4c-1.1-0.7-2.2-1.4-3.3-2.2c0.7,0.9,1.4,1.8,2.1,2.7
        C379.7,96.5,380.2,97.1,380.7,97.7z M400.2,57.9l0.8-0.2c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.6-0.1,0.9-0.2c-0.3-0.4-0.8-0.7-1.3-0.8
        c-1.3-0.3-2.3-1.2-2.9-2.4c-0.4-1.2-0.6-2.4-0.7-3.7c0-1.4-0.3-2.8-0.9-4h0l-0.4-0.5c-0.7-0.5-1.5-0.3-2.9,0.5
        c-2.2,1.4-4.8,2-7.4,1.6c-1.5-0.2-3-0.8-4.3-1.7c-0.7-0.5-1.5-0.9-2.3-1.2c-0.7-0.2-1.5-0.2-2.3-0.2c-1.5,0.1-3.4,0.2-4.8-2.9
        c-0.7-2-2.1-3.7-3.8-4.9c-0.9-0.6-1.7-1.1-2.6-1.6c-0.8,0.6-1.7,1-2.7,0.9c-1.8,0.1-1.8,0.1-3,1.4c-0.8,0.9-1.9,1.5-3,1.8
        c-1.5,0.5-1.6,0.6-1,2.1c0.4,1.2,0.7,2.2,0.2,3.2c-0.6,0.9-1.5,1.5-2.5,1.7l-0.7,0.2c-0.3,0.1-0.7,0.2-0.9,0.3
        c0,0.3,0.1,0.6,0.2,0.9c0.4,1.2,1.2,3.6-0.2,4.9c-1.3,1.3-2.8,0.6-3.6,0.2c-0.2-0.1-0.4-0.2-0.5-0.2c-0.4-0.1-0.8-0.1-1.3,0
        c-0.5,0.1-0.9,0.1-1.4,0.1c-1.1-0.1-2.2-0.4-3.1-1l-0.6-0.3c-1.3-0.6-1.8-0.5-2.2-0.3c-0.2,0.1-0.3,0.3-0.5,0.5
        c-0.5,0.8-1.3,1.3-2.2,1.4c-0.8,0.1-1.6,0-2.3-0.3c-0.3-0.1-0.7-0.2-1.1-0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-1.1,0.5-2.4,0.5-3.4,0
        c-0.7-0.4-1.3-1.1-1.4-1.9c0-0.2,0-0.3,0-0.5c-0.3,0.1-0.6,0.2-0.8,0.5c-0.4,0.8,0.1,1.7,0.9,3.1c0.4,0.6,0.7,1.2,0.9,1.8
        c0.2,0.6,0.4,1.2,0.6,1.8c0.2,1.3,0.8,2.5,1.8,3.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.2,0.1-0.3c0.2-0.8,0.5-1.5,1.1-2.1
        c0.4-0.3,0.9-0.5,1.4-0.6c0.2,0,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.8-0.7c0.4-0.6,0.6-1.3,0.5-2c0-0.2,0-0.4,0-0.7l0-0.9l0.9-0.2
        c3-0.7,6.9,1.5,7.7,4.4c0.2,1,0.3,2.1,0.2,3.1c-0.1,0.8,0,1.6,0.2,2.4c0.6,2,3,3.8,4.7,5l0.3,0.2c0.2,0.2,0.5,0.4,0.8,0.6
        c1.7,1.1,3.7,2.5,3.7,5.3l0,1.1l-1.1,0.1c-1,0-2.1-0.3-3-0.8c-0.5-0.2-1.1-0.4-1.6-0.6c0,0.3,0.4,1.2,2.1,3.2
        c0.2,0.2,0.4,0.5,0.5,0.6c1.3,1.8,2.4,2.1,4.4,2.6l0.9,0.3c1.5,0.3,2.5,1.7,2.2,3.3c-0.1,0.4,0,0.9,0.3,1.2c0.4,0.3,2.1,0.2,2.8,0.1
        c0.4,0,0.8-0.1,1.2-0.1c1.6,0.1,3.1,0.6,4.3,1.6c0.6,0.4,1.1,0.7,1.8,1c0.5,0.2,1,0.3,1.5,0.3c0.5,0,1,0.1,1.5,0.2
        c0.6,0.1,1.2,0.4,1.8,0.7c-0.3-0.5-0.5-0.9-0.9-1.3c-0.8-0.9-1.7-1.7-2.7-2.4c-0.6-0.4-1.1-0.8-1.6-1.2c-1.4-1.2-2.5-2.7-3.3-4.4
        c-0.4-0.7-0.8-1.4-1.2-2.1c-0.5-0.6-1-1.2-1.6-1.7c-0.8-0.7-1.5-1.4-2.1-2.3c-0.5-0.9-0.9-1.9-1.2-3c-0.2-0.7-0.4-1.5-0.8-2.1
        c-0.4-0.7-1-1.3-1.8-1.6c-0.6-0.3-1.2-0.7-1.7-1.2c-2.4-2.3-1.2-4.7-0.7-5.7c0.3-0.6,0.5-1.2,0.6-1.8c0-0.2,0-0.4,0-0.6
        c-0.1-0.9,0.2-1.9,0.8-2.6c0.5-0.5,1.1-0.7,1.8-0.6c1.4,0.3,2.2,1.9,3.1,3.4c0.2,0.4,0.4,0.9,0.7,1.3c1.2,1.2,3-0.3,3.7-0.9
        c0.4-0.4,0.7-0.7,0.9-0.9c1.4-1.5,2.1-1.6,4.7-0.8c1.1,0.3,2.3,0.5,3.4,0.6c0.6,0,1.3-0.1,1.9-0.2c0.8-0.2,1.7-0.3,2.5-0.2
        c1,0.1,1.9,0.5,2.8,1c0.6,0.5,1.4,0.7,2.1,0.7c0.8-0.1,1.6-0.2,2.3-0.3c2-0.3,4-0.7,6.1,0.3c0.7,0.4,1.4,0.8,2.1,1.2
        c1.7,1.1,3.4,2.1,5,1.6c0.1,0,0.3-0.1,0.4-0.2c0-0.4,0-0.8-0.1-1.2c-0.1-0.6-0.1-1.3-0.1-1.9L400.2,57.9z"
                />
                <path
                    id="Cyprus"
                    filter={isNotMc && currentCountry === 'Cyprus' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Cyprus' })}
                    onClick={(): void => setCurrentCountry('Cyprus')}
                    d="M558.1,161.7c0.8-0.7,1.7-1,2.7-1.1c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.5-0.2,0.7-0.4
        c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.7-0.3-1.4-0.3-2.2c0-0.6,0-1.1,0.1-1.5c0.2-0.9-0.1-1.8-0.8-2.4l-0.3-0.4c-0.9-1.1-2.3-2.8-2-5.2
        l0.1-0.3l0.1-0.2c0.6-0.7,1.4-1.3,2.2-1.8c0.6-0.4,1.2-0.8,1.8-1.2c-0.1,0-0.2-0.1-0.3-0.2c-1.2-0.6-3.1,1.4-4.2,2.7
        c-0.2,0.3-0.5,0.5-0.7,0.7c-1.3,1.1-2.7,2-4.3,2.7c-1.2,0.5-2.3,1.1-3.3,1.9c-1.2,1.3-2.2,2.8-2.9,4.5c-0.6,1.5-1.5,2.9-2.5,4.2
        c-1.7,2-3.2,4.2-4.6,6.5c-0.1,0.2-0.2,0.3-0.3,0.5c1.2,0.3,2.4,0.4,3.6,0.3c0.3,0,0.5,0,0.6,0c0.2,0,0.4,0,0.5-0.1
        c0.2-0.1,0.3-0.3,0.4-0.4c0.5-0.7,1.3-1.1,2.2-1.2c0.9,0.1,1.8,0.6,2.2,1.4c0.1,0.1,0.1,0.1,0.2,0.2c0.4-0.2,0.7-0.4,1-0.7
        c0.3-0.3,0.6-0.7,0.8-1.1c0.5-1,1.2-1.8,2.1-2.3c1-0.4,2.2-0.4,3.2,0.2c0.2,0.1,0.4,0.2,0.5,0.2c0-0.1,0-0.2,0-0.3
        C557.2,163.1,557.5,162.3,558.1,161.7z"
                />
                <path
                    id="Greece"
                    filter={isNotMc && currentCountry === 'Greece' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Greece' })}
                    onClick={(): void => setCurrentCountry('Greece')}
                    d="M467.2,233.7c0.6,0.5,3.3,0.1,4.5-0.2c0.6-0.1,1.2-0.2,1.6-0.2c2.5-0.3,4.3,1.1,6,2.3
        c1,0.8,2.1,1.5,3.4,1.9c2.2,0.3,4.4,0.3,6.6-0.1c1.5-0.2,3-0.4,4.5-0.4c1.5,0.1,2.9,0.2,4.4,0.6c3.1,0.6,6.1,1.2,8.1-0.7
        c0.5-0.5,1-1,1.3-1.7c0,0,0,0-0.1,0c-1.1-0.3-2.3-0.4-3.4-0.1c-0.2,0.1-0.5,0.2-0.7,0.4c-0.5,0.4-1.1,0.7-1.8,0.8l-0.7,0.1l-0.4-0.5
        c-0.4-0.5-0.7-1.1-0.9-1.7c-0.2-0.6-0.2-0.6-0.7-0.7c-0.8-0.2-1.6-0.2-2.4-0.2c-2.2,0-4.5-0.2-6.7-0.6c-1.2-0.2-2.5-0.4-3.7-0.5
        c-1,0-2.1,0.2-3,0.6c-0.6,0.2-1.2,0.5-1.9,0.7c-2.2,0.6-3.7,0.1-4.5-1.3c-0.3-0.6-0.4-1.3-0.3-2c0-0.2,0-0.4,0-0.6
        c-0.1,0-0.8,0.3-1.2,0.4c-0.5,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.7-0.4-2.3-1c-0.2-0.2-0.4-0.4-0.6-0.4c-0.6-0.1-1.2-0.1-1.8,0.1
        l-0.1,0.7l-0.7,0.4c-0.7,0.6-1.2,1.5-1.3,2.4C467,232.7,467,233.2,467.2,233.7z M474.4,176.1c0-1.5-0.2-1.6-0.8-1.8
        c-0.5-0.1-1.1-0.2-1.7-0.3c-0.8-0.1-1.7-0.2-2.5-0.5c-1.2-0.5-2.2-1.1-3.2-2c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-0.2-0.6-0.3-0.9-0.4
        c-0.4-0.2-0.5-1.1-1-1.4c-0.6-0.4-1.1,0.1-1.3-0.2c-0.2-0.3-0.4-0.6-0.7-0.8c-0.5-0.4-0.5,0-1.8,0.1c-0.2,0.1-0.4,0.4-0.4,0.7
        l-1.2,0.3l0,0l1.2-0.3l0,0c0.3,0,0.6,0.1,0.9,0.1c0.6,0,0.7,0.8,1.3,1.1c1.3,0.6,2.5,1,2.9,1.9c0.2,0.7,0.7,1.2,1.2,1.7
        c0.7,0.4,1.3,0.9,1.8,1.5c0.6,0.7,1.3,1.1,2.2,1.4c0.5,0.1,1,0.1,1.5,0.2 M479.6,129.5c0.4,0.2,0.8,0.4,1.3,0.5
        c0.6,0,1.2-0.2,1.6-0.7c0.6-0.5,1.4-0.9,2.2-1.1c1.7-0.4,3.6,0.5,5.2,1.1c0.2,0.1,0.5,0.2,0.7,0.3c1.5,0.5,3.1,0.6,4.7,0.3
        c0.9-0.1,1.7-0.2,2.6-0.1c0.7,0.1,1.4,0.3,2,0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.7,0.1,1.1,0c1.1-0.5,1.9-2.3,2-3.8
        c0-0.3,0-0.5,0-0.8c-0.1-1.6,0.4-3.2,1.5-4.4c0.4-0.4,0.9-0.7,1.4-1c0.4-0.2,0.7-0.4,1-0.7c0.4-1-0.7-3-1.7-3.5
        c-0.4-0.2-0.7-0.2-1.1-0.2l-0.9,0c-0.4,0-0.8-0.1-1.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.7,0.4,1.5,0.6,2.1c0.3,0.7,0.4,1.3,0.5,2
        c0.1,2.6-1.7,2.6-2.8,2.7c-0.9,0-1.7,0.2-2.5,0.5l-0.7,0.2c-2.9,1-6.4,2.2-9.5,0.7c-0.5-0.3-1.1-0.6-1.5-1c-0.6-0.6-1.4-0.9-2.3-0.9
        c-0.2,0-0.4,0.1-0.6,0.1c-1,0.3-2.1,0.2-2.9-0.4c-0.4-0.3-0.7-0.6-0.9-1.1c-0.1-0.1-0.2-0.2-0.2-0.4c-1.1-0.9-4.5-1.4-5.4-0.8
        c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.6-0.8,1-1.3,1.4c-0.5,0.2-0.9,0.3-1.4,0.4c-0.3,0.1-0.6,0.1-0.9,0.3c-0.4,0.2-0.8,0.4-1.2,0.7
        c-1.2,0.7-2.6,1.6-4.4,1c-0.8-0.3-1.5-0.7-2.2-1.1c-0.8-0.6-1.8-1-2.8-1.2c-0.6,0-1.3-0.1-1.9,0c-0.4,0-0.8,0-1.2,0
        c-0.1,0.2-0.2,0.4-0.3,0.7c-0.6,1-1,2.1-1.1,3.3c-0.1,4.3-5.4,4.3-7.2,4.4c-0.9,0-1.9-0.1-2.8-0.3c-0.4-0.1-0.8-0.2-1.2-0.2
        c-0.8-0.1-1.5,0.3-2.6,1l-0.1,0.1c-0.3,0.2-0.7,0.5-1,0.8c-0.8,0.8-1.8,1.4-2.9,1.7c-0.6,0.2-1.3,0.2-2,0.2c-0.5,0-1,0.1-1.5,0.2
        c-0.4,0.2-0.8,0.4-1.1,0.6c-0.7,0.6-1.6,1-2.5,1.2c-0.3,0.1-0.6,0.1-0.9,0.2c0.4,1.1,0.6,2.2,0.4,3.4c-0.3,1.5-1.2,2.8-2.5,3.7
        c-0.4,0.3-0.8,0.7-1.2,1c-0.5,0.7-0.8,1.5-1,2.3c-0.4,1.4-0.8,3.1-2.6,4.1c-0.5,0.3-1.1,0.5-1.7,0.7c-0.4,0.1-0.7,0.2-1.1,0.4
        c-0.5,0.3-0.5,0.3-0.7,1.1l0,0.2c-0.2,1.1-0.5,2.8-1.7,3.7c0,0.2,0,0.5,0.1,0.7c0.4,1.5,1.1,2.9,2,4.2c0.3,0.5,0.7,1.1,0.9,1.6
        c0.2,0.3,0.3,0.6,0.5,0.9c0.3,0.9,0.9,1.7,1.7,2.2c1,0.5,2.2,0.8,3.3,0.7c0.3,0,0.6-0.1,1-0.1c0.7-0.1,1.4-0.2,2.1-0.1
        c1,0.1,1.8,0.5,2.4,1.2c0.3,0.5,0.4,1.1,0.3,1.7c-0.4,1.4-1.8,1.5-2.5,1.6c-0.1,0-0.2,0-0.3,0c-0.5,0.1-0.8,0.1-1.1,0.1
        c0,0.4,0.1,0.8,0.2,1.2c0.3,0.8,0.8,1.5,1.5,2c0.8,0.8,1.5,1.7,1.9,2.8c0.2,0.5,0.3,1,0.3,1.5c0,0.5,0.2,1,0.4,1.4
        c0.4,0.4,1.9-0.1,2.3-0.2c0.7-0.2,1.4-0.4,2.1-0.7c1.3-0.5,2.5-0.8,3.9-1c1.1-0.1,2.2-0.1,3.3-0.1c1.3,0.1,2.7,0.1,3.9-0.3
        c1-0.4,2.2-0.2,3,0.5c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0,0.5,0,0.7-0.2c0.9-0.5,2-0.4,2.8,0.2c0.4,0.4,0.8,0.8,1.1,1.3
        c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,1.1,0.6,1.7,0.7c0.2,0,0.3,0,0.5,0c1.6-0.1,2.9-0.1,3.7,0.6c0.3,0.3,0.5,0.7,0.5,1.2
        c-0.1,1.2-1.3,1.7-2.4,2.1c-0.3,0.1-0.7,0.3-0.9,0.4c0.1,0.1,0.2,0.2,0.3,0.2c1,0.1,2.1-0.1,3-0.6c0.7-0.3,1.5-0.5,2.2-0.6
        c0.4,0,0.9,0.1,1.3,0.2c0.8,0.4,1.5,1,2,1.8c0.1,0.2,0.3,0.4,0.4,0.6c1,1,2.3,1.8,3.7,2.3c0-0.1,0-0.3,0-0.4
        c-0.1-2.4-0.9-4.7-2.2-6.6c-1.6-2.1-4.2-3.6-6.9-4.8c-1.2-0.5-2.2-1.2-3-2.2c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.3-0.4-0.6-0.6-0.9
        c-0.5-0.5-1.2-0.9-1.9-0.8c-0.2,0-0.4,0-0.7-0.1c-2-0.2-3.8-0.4-4.7-1.5c-0.5-0.6-0.6-1.4-0.3-2.1c0.6-1.3,2.3-1.5,3.3-1.6
        c0.5,0,1-0.2,1.3-0.6c0.1-0.4,0.1-0.8-0.2-1.2c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.5-1.4-1.4-1.5-2.4c0-0.7,0.4-1.4,1-1.8
        c1.5-1.3,3.7-1.4,5.4-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.4-0.8-1-1.3-1.8-1.7c-1.6-0.6-2.7-2-2.9-3.7
        c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.2,0-0.3,0-0.5c-0.4-0.3-0.8-0.6-1.3-0.7c-0.5-0.2-1-0.5-1.5-0.8c-1-1-1.7-2.3-1.9-3.7
        c-0.1-0.7,0-1.5,0.2-2.2c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.7,0-1.5-0.4-2.2c-0.9-1.4-0.7-3.3,0.4-4.5c1.4-1.7,3.7-2.2,5.7-1.4l0.7,0.3
        v0.8c0,0.8-0.2,1.5-0.4,2.2c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.5,0.1,0.9,1.8,1.7l0.6,0.3c0.7,0.4,1.5,0.7,2.3,0.7
        c0.9-0.1,1.8-0.2,2.6-0.4c0.5-0.1,0.9-0.2,1.4-0.3c1.3-0.3,2.8-0.9,2.7-2.5c0-0.6-0.1-1.3-0.4-1.9c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.3-0.3-0.6-0.7-0.8-1.1c-0.5-1-0.6-2.1-0.1-3.1c0.5-0.9,1.4-1.4,2.4-1.5c1,0,1.9,0.3,2.8,0.8c0.4,0.3,0.8,0.5,1.3,0.5
        c0.5-0.4,0.9-0.8,1.2-1.4c0.6-1.1,1.5-2,2.6-2.5C477.1,128.6,478.5,128.8,479.6,129.5L479.6,129.5z M464,197.5
        c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0-0.3,0-0.7-0.2-0.9c-0.2-0.3-0.4-0.6-0.7-0.8c-0.4-0.4-0.8-0.9-1.2-1.4l-0.2-0.4
        c-0.2-0.4-0.5-0.8-0.9-1.1c-0.7-0.5-1.5-0.9-2.3-1.2c-0.4-0.2-0.8-0.3-1.2-0.5c-0.9-0.5-1.6-1.1-2.2-1.9c-0.2-0.2-0.3-0.4-0.5-0.6
        c-1-1.1-2.4-1.9-4-2.1c-1.1-0.3-2.2-0.8-3-1.7c-0.4-0.4-0.8-0.7-1.3-1c-1-0.5-3.5-1.6-5.6-1.1c-0.6,0.2-1.2,0.5-1.7,0.8
        c-0.8,0.5-1.7,0.8-2.7,0.9c-1.8,0.3-1.8,0.3-2.3,2c-0.3,1-0.9,2-1.8,2.6l1.2,0.8l-2.8,0c0.1,0.1,0.3,0.2,0.4,0.3
        c0.9,0.6,1.7,1.3,2.4,2c0.5,0.6,1.1,1.1,1.7,1.6c1.8,1.3,3.3,3,4.3,5c0.9,1.4,1.1,3.2,0.5,4.8c-0.6,2-0.2,3.3,1.3,4.9
        c0-0.2,0-0.4,0-0.6c0-1.1,0.4-2.2,1.2-3c0.6-0.5,1.3-0.7,2.1-0.6c2.7,0.2,3.5,3.7,3.8,5c0.1,0.2,0.1,0.5,0.2,0.8
        c0.2,1.1,0.5,2.1,0.9,3.1c-0.3-1.1-0.2-2.3,0.3-3.3c0.5-1,1.4-1.7,2.4-1.9c0.2,0,0.3,0,0.5,0c0.8,0,1.5,0.3,2,0.8
        c0.6,0.7,1.1,1.6,1.3,2.5c0.1,0.5,0.3,0.9,0.5,1.3c0.5,0.5,1.1,0.8,1.8,1c-0.1-0.2-0.2-0.3-0.2-0.4c-0.2-0.3-0.4-0.6-0.5-1
        c-0.3-1-0.2-2,0.3-2.9c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.3,0-1.3-1.3-3.8c-0.3-0.6-0.6-1.2-0.9-1.8c-0.6-1.4-1.4-2.7-2.2-4
        c-0.1-0.1-0.2-0.3-0.3-0.5c-0.8-1.2-2.4-3.3-0.7-5.2c1.2-1.3,3-1.2,5,0.2c0.9,0.6,1.6,1.5,1.9,2.5c0.1,0.4,0.2,0.8,0.2,1.2
        c0,0.1,0,0.2,0,0.2c0.3-0.3,0.6-0.5,1-0.6C462.4,196.8,463.3,196.9,464,197.5L464,197.5z M424.6,182.3c0.1-0.6,0.1-1.2-0.1-1.8
        c-0.3-0.3-0.6-0.5-1-0.6c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.3-1,0.5-1.5,0.6l-0.1,0l-0.1,0c-0.8,0.5-1.5,1.1-2.1,1.8
        c-0.1,0.2-0.2,0.3-0.3,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.3,0.8,0.4,1.3,0.5c0.2,0.1,0.5,0.1,0.8,0.2c0.6,0.2,1.2,0.5,1.8,0.8
        c0.3,0.2,0.6,0.3,0.9,0.4c0.3,0.2,0.7,0.3,1,0.4c-0.1-0.1-0.1-0.3-0.2-0.4C424.5,184.2,424.5,183.3,424.6,182.3L424.6,182.3z"
                />
                <path
                    id="Italy"
                    filter={isNotMc && currentCountry === 'Italy' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Italy' })}
                    onClick={(): void => setCurrentCountry('Italy')}
                    d="M351.3,180.7c-1.2,0.2-2.4,0.6-3.5,1.2c-1.7,0.8-3.4,1.7-5.6,1c-0.3-0.1-0.6-0.2-0.8-0.2
        c-1.4-0.4-2-0.6-3.2,0c-0.3,0.2-0.6,0.3-0.9,0.5c-1.6,1.1-3.5,1.5-5.4,1.3c-0.7-0.1-1.4-0.3-2-0.5c-0.8-0.3-1.7-0.4-2.6-0.3
        c-2.2,0.5-4.5,0.3-6.6-0.6c-0.5-0.2-0.9-0.4-1.4-0.6c-1.1-0.7-2.4-1-3.7-0.9c-0.5,0.1-0.9,0.3-1.3,0.5c-0.9,0.6-2,0.8-3.1,0.6
        c-0.8-0.2-1.5-0.6-2.1-1.1c-0.9-0.6-1-0.7-1.3-0.5c-0.3,0.2-0.6,0.4-0.8,0.6c-0.5,0.5-1.2,0.9-2,1.1c0,0.2,0,0.4,0,0.6
        c0.1,0.9,0,1.8-0.2,2.7c-0.1,0.2-0.2,0.5-0.2,0.7c-0.3,1-0.5,1.4-0.1,2.2c0.6,1.1,1.6,1.3,3.4,1.6c1.3,0.1,2.5,0.5,3.7,1.1
        c0.7,0.4,1.3,0.8,1.9,1.3c0.5,0.4,1,0.8,1.6,1.1c0.6,0.3,1.2,0.6,1.9,0.8c0.5,0.1,0.9,0.3,1.4,0.5c1.5,0.6,3,1.4,4.3,2.4
        c0.4,0.4,0.9,0.9,1.2,1.4c0.4,0.7,1,1.2,1.7,1.5c0.8,0.2,1.6,0.2,2.4,0.2c0.3,0,0.5,0,0.8,0c1.4-0.1,2.9,0.3,4.1,1.2
        c0.5,0.4,1,0.9,1.4,1.4c0.2,0.3,0.5,0.6,0.8,0.9c0.3,0.2,0.7,0.5,1,0.6c0.4,0.2,0.9,0.5,1.3,0.8c1.7,1.4,2.9,2.3,4.9,2.4
        c0.3,0,0.6,0,0.9,0c0-0.1,0-0.2,0-0.3c0-0.8,0.2-1.6,0.5-2.3c0.4-0.7,1-1.2,1.7-1.5c0.3-0.1,0.5-0.3,0.7-0.4c0.1-0.4,0-0.9-0.2-1.3
        c-0.1-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.1-0.5-0.2-0.8c-0.3-1.4-0.5-1.9-1.6-2.5l-1-0.5l0.6-1.8c0.5-1.2,0.7-2.4,0.7-3.7
        c0-2.5,0.2-3.9,2.4-5.7l0.4-0.3C348.3,185.1,351.6,182.5,351.3,180.7z M266.6,135.8c0.3-0.4,0.5-0.8,0.7-1.2
        c0.1-1.6-0.1-3.3-0.5-4.8l-0.2-0.7c-0.1-0.6-0.5-1.2-1-1.6l-0.4-0.5c-0.9-1-1.4-2.3-1.2-3.7l0-0.6v-0.5c-0.1,0-0.2-0.1-0.3-0.1
        c-1-0.4-2.3,0.5-3.4,1.2l-0.1,0.1c-2.4,1.5-5.5,3.4-8.5,3.8c-1.4,0.2-2.8,0.2-4.2-0.1l-0.9-0.2l-0.1-0.9c0-0.1,0-0.3,0-0.5
        c-0.1,0.2-0.2,0.3-0.2,0.5c-0.4,0.7-0.7,1.5-0.8,2.3c0,0.2,0,0.4,0,0.6c0.2,0.1,0.4,0.1,0.6,0.2c0.7,0.2,1.4,0.6,2,1.1
        c1.2,1.3,1,3.7,0.9,5.6c0,0.5-0.1,0.9-0.1,1.2c0,0.4,0,0.9,0,1.4c-0.1,1,0,2,0.1,3c0.6,2.2,0.3,4.6-0.8,6.6
        c-0.4,0.8-0.8,1.4-1.2,2.1c-0.5,0.7-0.9,1.5-1.3,2.3c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.7-0.6,1.4-1.3,1.8
        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.2,0.5-0.2,0.7c0.3,0.3,0.8,0.5,1.2,0.5c1,0.2,2,0.6,2.9,1.2c0.8,0.6,1.7,0.9,2.7,0.8
        c1-0.2,1.9-0.5,2.8-1c0.3-0.2,0.5-0.6,0.6-0.9c0.2-0.5,0.5-1,0.8-1.4c1.8-2.2,4.1-0.7,5.5,0.2c0.4,0.3,0.8,0.5,1.2,0.7
        c0.4,0.3,0.9,0.3,1.3,0.2c0.5-0.5,0.7-1.2,0.6-1.9l0-0.8c-0.1-1.2,0-2.4,0.2-3.5c0.3-1.1,0.9-2.2,1.5-3.1c0.3-0.5,0.6-1,0.9-1.5
        c0.9-1.8,0.6-3.9,0.3-6.1C265.1,139.2,265.5,137.3,266.6,135.8L266.6,135.8z M387,144.8c3.7,0.2,4.7,2.6,5.8,5.1
        c0.3,0.9,0.9,1.7,1.8,2.1c0.4,0.1,0.8,0.1,1.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.2,0.1-0.3c0-0.4,0.1-0.7,0.3-1.1
        c0.1-0.3,0.3-0.7,0.4-1c0.3-0.4,0.4-0.8,0.4-1.3c-0.3-1.9-1-3.8-2-5.4c-0.7-1-2-1.4-3.5-1.9c-1.2-0.3-2.4-0.8-3.4-1.4
        c-0.9-0.7-1.6-1.5-2.2-2.5c-0.4-0.8-1-1.5-1.7-2c-1.1-0.6-2.3-0.9-3.6-1c-1.7-0.1-3.3-0.6-4.8-1.4c-0.8-0.6-1.6-1.2-2.3-1.8
        c-1-1-2.2-1.8-3.6-2.4c-0.6-0.2-1.2-0.5-1.8-0.8c-0.5-0.3-1-0.5-1.6-0.7l-0.2-0.1c-1.6-0.3-3.1-1-4.4-2c-2.2-1.7-2.7-3.7-1.7-5.8
        c0.3-0.5,0.7-1,1.1-1.4c0.3-0.3,1.2-1.4,1.1-1.6c-0.4-0.6-1.3-0.3-2.9,0.4c-0.4,0.2-0.8,0.3-1.1,0.4c-1.2,0.3-2.5,0.3-3.7-0.1
        c-0.9-0.2-1.9-0.3-2.9-0.2c-1.8,0.1-4.1,0.2-5.8-1.1c-0.9-0.7-1.6-1.6-2.2-2.6c-0.4-0.7-0.8-1.3-1.4-1.8c-0.9-0.6-1.8-1-2.8-1.4
        c-1.2-0.4-2.2-0.9-3.2-1.6c-1.9-1.5-3.2-3.7-3.7-6.1c-0.2-0.7-0.3-1.3-0.3-2c0-0.4-0.1-0.7-0.1-1.1c-0.2-0.8-0.5-1.6-0.8-2.4
        c-0.6-1.2-1-2.6-1.1-4c-0.9-1.5-1.6-3.2-2.1-4.9c-0.9-2.7-1.6-5-4.4-6.3c-2.8-1.2-7.9-3.9-10.9-7.8c-0.3-0.4-0.7-0.8-1.2-1.4
        c-2.3-2.4-5.5-5.8-3.9-8.7c0.6-0.9,1.4-1.6,2.4-2.1c0.4-0.2,0.8-0.5,1.2-0.8c1-0.9,1.8-2.1,2.3-3.4c0.1-0.6,0.1-1.2-0.1-1.8
        c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.5-1.3-1.2-1.6-2.1c-0.1-1.6,0.5-3.2,1.7-4.3c0.7-0.7,1.7-1,2.6-0.9c0.2,0,0.4,0,0.7,0
        c1.4-0.2,2.7-0.7,3.8-1.4c1.8-1,3.8-1.8,5.8-2.1c0.7-0.1,1.4-0.1,2.1-0.1c0.6,0,1.1,0,1.7-0.1l-0.1-0.2l-0.1-0.5
        c-0.2-0.7-0.3-1.4-0.3-2.2c0.1-0.5,0.2-1,0.3-1.5c0.1-0.3,0.2-0.6,0.2-1c0.1-0.6-0.1-1.2-0.6-1.5c-0.3-0.3-0.7-0.7-0.9-1.1
        c-0.7-0.8-1-1.9-0.8-2.9c0.4-1.4,1.8-2,2.9-2.5l0.7-0.3l0,0c-0.8-0.1-2.1-0.2-2.7-0.2c-0.7,0-1.4-0.1-2.1-0.2
        c-0.7-0.2-1.4-0.6-2-1.2c-0.3-0.2-0.6-0.4-0.9-0.6c-0.9-0.4-1.8-0.6-2.8-0.7c-0.5-0.1-1-0.2-1.5-0.3c-2.6-0.6-5-2.1-6.7-4.2
        c-0.4-0.6-0.7-1.1-1-1.8c-0.8-1.5-1-1.8-1.7-1.6c-0.7,0.2-1.3,0.5-1.9,0.8c-1.2,0.8-2.7,1.2-4.2,1.2c-0.6,0-1.2-0.1-1.8-0.3
        c-1.5-0.3-2.4-0.4-3.2,0.3c-1.6,1.5-3.6,3-6.7,0.9l-0.2-0.1c-0.2-0.1-0.4-0.3-0.7-0.4l0,0.1c-0.1,0.2-0.1,0.4-0.1,0.6
        c0,0.3,0.1,0.7,0.2,1c0.3,0.9,0.8,2.7-0.9,3.7c-1.6,0.9-3,0.1-4-0.4c0,0.5,0.1,1,0.2,1.4c0.3,1.1,0.3,2.2,0,3.3
        c-0.2,0.7-0.7,1.3-1.4,1.5c-0.7,0.3-1.6,0.2-2.2-0.2c-0.7-0.4-1.2-1.1-1.3-1.9c0,0,0-0.1-0.1-0.1c-0.2,0.3-0.5,0.6-0.8,0.9
        c-1.4,0.9-3.3,0.2-4.3-1.1c-0.3-0.5-0.6-1.2-0.8-1.8c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0.1c-0.1,0.9-0.5,1.8-1.1,2.5
        c-0.4,0.4-0.9,0.7-1.3,0.9c-0.4,0.1-0.7,0.5-0.8,0.9l-0.2,0.4c-0.4,0.8-0.6,1.6-0.8,2.4c0,0.1,0,0.3,0,0.4c0.1,0.7,0,1.5-0.4,2.1
        c-0.4,0.6-1.1,1-1.9,1c-1.1-0.1-2.1-0.6-2.7-1.5c-0.9-1.2-1.6-2.6-2-4.1l-0.2-0.5c-0.2-0.4-0.4-0.7-0.7-1c-0.5-0.5-1-1.1-1.2-1.8
        c-0.3-0.9-0.3-1.8,0-2.6c0-0.2,0.1-0.4,0.1-0.6c-0.2,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-1.3,0.7-2.4,1.8-2.8,3.2
        c-0.2,0.3-0.3,0.7-0.5,1.1c-1.6,3-4.1,2.9-6.4,2.6l-0.6-0.1c-1.2-0.3-2.5-0.3-3.8-0.2c-0.8,0.2-1.7,0.4-2.5,0.7
        c-0.3,0.1-0.6,0.2-0.8,0.3l-0.1,0.3c-0.4,0.6-0.5,1.4-0.3,2.1c0.2,0.5,0.5,0.9,0.8,1.2c0.9,0.9,1.3,2.2,1.2,3.4
        c0.1,1.8-1,3.5-2.6,4.2c-0.6,0.3-1.3,0.5-1.9,0.7c-1.1,0.3-2.5,0.8-2.9,1.3c0,0.3,0.5,0.8,1.7,1.3c2.4,1,2.7,4.2,3,6.8
        c0.1,0.5,0.1,0.9,0.2,1.3c0.3,1.3,0.2,2.7-0.2,4c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.2-0.3,0.5-0.4,0.7c0.5,0.4,1.2,0.7,1.9,0.8
        c0.3,0.1,0.5,0.1,0.8,0.1c1,0,2,0.4,2.8,1c0.9,0.9,0.7,2.5,0.5,3.5l0,0.2c0,0.2-0.1,0.5-0.2,0.7c1.6,0,3.2-0.5,4.4-1.4
        c1.5-0.7,2.7-1.7,3.7-3l0.3-0.4c1.1-1.4,2.3-3.2,4.6-3.4c1.1,0,2.2,0.3,3.1,0.8c0.5,0.2,1,0.4,1.5,0.5c0.4,0.1,0.9,0.2,1.3,0.2
        c0.7,0.1,1.5,0.2,2.2,0.4c1.7,0.4,3.2,1.1,4.6,2.2c0.5,0.5,0.9,1.1,1.3,1.7c0.3,0.5,0.7,1,1.2,1.4c0.6,0.3,1.1,0.6,1.8,0.8
        c0.7,0.2,1.4,0.5,2,0.9c1.3,0.9,2.1,2.2,2.4,3.8l0.2,0.5c0.4,1.2,1,2.4,1.8,3.5c0.7,1.1,1.3,2.2,1.8,3.3c1,2.6,0.7,5.6-0.8,8
        c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.3,0.4-0.4,0.6c0.4,0,0.8-0.1,1.2-0.1c0.9-0.2,1.8-0.2,2.7,0c1.7,0.7,2.6,2.9,3.3,4.7
        c0.1,0.3,0.2,0.6,0.3,0.9c0.5,1.2,0.9,2.5,1.1,3.7c0,0.2,0.1,0.3,0.1,0.5l0.5,0c0.8-0.1,1.6,0,2.4,0.1c1.2,0.1,2.2,0.8,2.8,1.8
        l0.5,0.7c1.1,1.7,2.5,3.2,4.1,4.4c0.7,0.4,1.4,0.8,2.1,1c1.6,0.5,3,1.5,4,2.7c0.7,1.1,1.3,2.3,1.6,3.6c0.5,1.5,0.9,2.7,2.1,3.5
        c1.6,0.7,2.8,2.1,3.2,3.8c0.1,0.4,0.3,0.9,0.5,1.2h0.3l0.4,0.6c0.2,0.3,0.2,0.3,1.3-0.1c0.9-0.5,1.9-0.6,2.9-0.4
        c0.6,0.2,1,0.7,1.3,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0,0.8-0.1,1.3-0.2l0.4-0.1c0.1,0,0.2,0,0.3-0.1c0.6-0.3,1.2-0.3,1.8-0.1
        c1,0.3,1.8,0.9,2.4,1.6c0.7,0.9,1.2,2,1.4,3.1c0.4,1.5,0.7,2.4,1.5,2.6c0.4,0,0.9,0,1.2-0.2c0.9-0.2,2.3-0.6,3.4,0.6
        c0.6,0.8,0.9,1.8,0.8,2.8c0,0.5,0.1,1,0.3,1.4c0.4,0.4,0.9,0.4,2,0.2c1.2-0.2,2.9-0.5,4.5,0.9c1.9,1.8,1.8,3.9,1.6,5.8
        c0,0.5-0.1,1-0.1,1.5c0.9,0.2,1.8,0.6,2.6,1.2c0.4,0.4,0.8,1,0.9,1.5c0.1,0.2,0.1,0.4,0.2,0.5c0.5,0.4,0.6,0.3,1.5-0.1
        c0.2-0.1,0.3-0.2,0.5-0.2l0.2-0.1c1.1-0.5,3.2-1.4,4.5-0.2c0.6,0.7,0.8,1.6,0.6,2.5c0,0.2,0,0.3,0,0.5c0.2,1.1,0.8,2.2,1.7,3
        c1,1,1.8,2.3,2.2,3.7c0.2,1.2,0.2,2.4-0.1,3.6c-0.2,1.6-0.3,2.6,0.3,3.5c0.2,0.3,0.4,0.6,0.7,0.9c1.4,1.9,4,5.4,1.4,8
        c-1,0.9-2.3,1.3-3.7,1c-0.4,0-0.7,0-1.1,0c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.6,0,1.3,0,1.9c0.1,1.6,0.2,3.3-1,4.6
        c-0.6,0.5-1.2,1-1.9,1.4c-1.1,0.7-1.4,0.9-1.3,1.9c0,0.2,0.1,0.7,1.1,1c2.1,0.6,5.3-0.4,5.9-1.3c0.4-0.5,0.7-1.1,1-1.6
        c0.6-1.3,1.5-2.5,2.5-3.4c0.4-0.3,0.7-0.5,0.9-0.8c0.7-0.4,1.1-1,1.3-1.8c0.2-0.5,0.3-1,0.4-1.4c0.2-1.9,1.3-3.5,2.9-4.4
        c1.7-1,3.5-1.6,5.4-1.9l0.9-0.2c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.3-0.2-0.5-0.3-0.8c-0.3-0.6-0.5-1.3-0.6-1.9c0-0.7,0-1.4,0.2-2.1
        c0.2-0.8,0.2-1.5,0-2.3c-0.2-0.7-0.7-1.6-2.8-2.2c-0.4-0.1-0.9-0.2-1.3-0.2c-2-0.3-4.4-0.6-5.2-2.5c-0.8-2,0.4-6.6,1.8-8.7
        c0.4-0.6,0.9-1.1,1.6-1.4c0.2-0.5,0.4-1,0.6-1.5c0.2-0.5,0.5-1,0.7-1.6c0.8-2,1.6-3,4.1-3.4c1.6-0.4,3.3-0.1,4.6,0.9
        c0.7,0.6,1.2,1.3,1.7,2c0.3,0.6,0.8,1.2,1.4,1.6c0.8,0.4,1.6,0.5,2.5,0.4C386.2,144.8,386.6,144.8,387,144.8L387,144.8z"
                />
                <path
                    id="Portugal"
                    filter={isNotMc && currentCountry === 'Portugal' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Portugal' })}
                    onClick={(): void => setCurrentCountry('Portugal')}
                    d="M47,89.4c0.3-0.1,0.6-0.2,0.9-0.3c2.7-1.1,3.2-4.3,3.7-7.4c0.1-0.5,0.1-0.9,0.1-1.4
        c-0.1-1.6,0.8-3.2,2.3-3.9c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.1,0.6-0.3,0.8-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.4-0.8,0.7-1.2
        c0.6-0.6,1.4-1,2.2-1.2l0.4-0.1c0.3-0.1,0.6-0.2,1.1-0.3c3.1-0.9,4.7-1.7,4.8-2.3c-0.1-0.4-0.3-0.7-0.6-0.9
        c-0.3-0.2-0.6-0.3-0.9-0.3C62.1,68.6,61,67.4,61,66c0-0.6,0.3-1.2,0.6-1.7c0.1-0.2,0.2-0.3,0.3-0.5c0-0.2,0.1-0.3,0.1-0.5
        c-0.8,0-1.5-0.3-2.1-0.8c-0.4-0.4-0.7-0.8-0.9-1.4c0-0.1,0-0.1-0.1-0.2c-1-0.2-2.1-0.1-3.1,0.3c-1,0.3-2.1,0.5-3.2,0.5
        c-1.9-0.2-3.7-1.1-4.9-2.6c-0.3-0.3-0.5-0.5-0.8-0.8s-0.4-0.3-1.1-0.1c-0.8,0.3-1.6,0.4-2.4,0.3c-0.9,0-1.8-0.4-2.4-1.1
        c-0.5-0.7-0.6-1.6-0.4-2.5c0.1-0.3,0.1-0.6,0-0.9c-1.2-0.1-2.3,0-3.5,0.1l-0.7,0c-1.3,0-3,0.1-3.9,0.7l0,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c-1,1.4-0.8,4.7-0.6,6.9c0.1,0.8,0.1,1.5,0.1,2.1v0.4c0.1,1-0.1,2-0.6,2.8c-0.3,0.4-0.7,0.8-1.1,1c0,0,0,0.1,0,0.2
        c-0.1,0.4-0.1,0.8,0,1.2c0.2,1.4-0.2,2.7-1.2,3.7c-0.6,0.6-1.2,1-1.9,1.4c-0.5,0.3-1,0.7-1.5,1.1c-1.1,1.3-2.1,2.7-2.8,4.2
        c-0.3,0.7-0.6,1.5-0.8,2.2c-0.4,1.7-1.2,3.3-2.4,4.6c-0.8,0.7-1.7,1.1-2.6,1.4c-0.5,0.1-1,0.3-1.5,0.6c-0.6,0.6-1.2,1.4-1.5,2.2
        c-0.5,1.2-1.2,2.2-2.1,3.1c-0.7,0.5-1.5,0.9-2.3,1.1c-0.6,0.1-1.1,0.4-1.5,0.8c-0.1,0.2-0.2,0.5-0.2,0.8c-0.1,0.4-0.2,0.9-0.4,1.3
        c-0.8,1.5-1.9,2.7-3.2,3.6c-0.8,0.6-1.4,1.4-1.7,2.4c0,0.2,0.1,0.4,0.2,0.5c1,0.7,2.2,1.1,3.4,1.1c0.3-0.2,0.6-0.4,0.9-0.7
        c0.8-1,2.1-1.5,3.3-1.2l1,0.3l-0.1,1.1c-0.1,2.2-1.4,4.2-3.5,5.1c-0.1,0.1-0.4,0.1-0.7,0.2c-0.2,0-0.5,0.1-0.8,0.2
        c0.5,0,1.1-0.1,1.5-0.1c0.6-0.1,1.2-0.1,1.8-0.1c3.6,0.5,3.5,3.3,3.4,4.9l0,1l-1,0.2c-0.8,0.1-1.5-0.2-2.1-0.7l0,0
        c-0.1,0.3-0.2,0.8-0.3,1.1c-0.1,0.4-0.2,0.7-0.3,1c-0.3,1.2-1.2,2.2-2.4,2.7c-0.2,0.1-0.4,0.2-0.7,0.4c-0.2,0.1-0.3,0.2-0.4,0.3
        c0,0.1,0,0.3,0,0.5v0.4c0,1.1-0.1,2.2-0.3,3.2c-0.2,0.6-0.4,1.2-0.7,1.8c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.5-0.2,1.1-0.2,1.6
        c0.1,1.3-0.3,2.5-1.1,3.5c-0.6,0.6-1.2,1.2-2,1.7c-0.2,0.2-0.5,0.3-0.7,0.6c0.2,0,0.4,0,0.6,0c1.6-0.1,3.2,0.2,4.6,0.8
        c0.7,0.3,1.5,0.6,2.2,0.9l0.6,0.2c1.1,0.4,2,1.1,2.8,1.9c0.6,0.6,1.3,1.1,2.1,1.4c0.8,0.1,1.6,0,2.3-0.2c0.7-0.2,1.4-0.3,2.1-0.3
        c0.6,0,1.3,0.1,1.9,0.2c0.6,0.2,1.3,0.2,1.9,0.1c0.4-0.1,0.8-0.3,1-0.7c0.8-1,0.8-2.6,0.8-4c0.1-2.5,0.4-3.6,2.9-4.8
        c1.3-0.6,2.4-1.3,3.5-2.2c0.5-0.4,1-0.7,1.6-1c0.7-0.4,1.4-0.9,2.1-1.4c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.3-0.7-0.5-1
        c-1.2-2.9-2-6.7,2.1-9.1c0.5-0.3,1-0.5,1.5-0.8c2.7-1.4,4-2.3,3.6-4.2c-0.2-0.6-0.4-1.2-0.7-1.7c-0.4-0.7-0.7-1.5-0.9-2.3
        c-0.3-1.3-0.6-2.6-1.1-3.9l-0.2-0.5c-0.6-1.6-1-2.6-0.3-3.5c0.6-0.9,1.8-1,3.1-0.9c1.5,0,3.1,0.1,4.6,0c0.1-0.2,0.2-0.3,0.3-0.5
        c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.6,0.1-1.2,0-1.8c0-0.4-0.1-0.8-0.1-1.1C44.3,90.8,44.9,90.1,47,89.4z"
                />
                <path
                    id="Spain"
                    filter={isNotMc && currentCountry === 'Spain' ? 'url(#filter-blur)' : ''}
                    className={cx('main-map__country', { active: currentCountry === 'Spain' })}
                    onClick={(): void => setCurrentCountry('Spain')}
                    d="M206.9,130.6c-0.2-0.1-0.4-0.1-0.6-0.2c-0.4-0.2-0.7-0.4-1-0.6c-1.2-0.8-1.9-1.1-2.4-0.7l-0.4,0.4
        c-0.1,0.2-0.2,0.4-0.2,0.7c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.7,0.1,1.1,0.3c0.9,0.5,1.6,1.3,1.9,2.3c0.4,0.8,0.5,1.1,0.8,1.1
        c0.1,0,0.3,0,0.4-0.1c0.6-0.5,1-1.2,1.1-2C208.1,131,208.1,131,206.9,130.6z M202.1,130.4L202.1,130.4z M194.1,134.1
        c0.2-0.2,0.3-0.5,0.5-0.7c-0.1,0-0.3-0.1-0.4-0.1l-0.5-0.1c-0.9-0.1-1.7-0.8-2-1.7c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.2-0.1-0.4-0.2-0.6
        c-0.2-0.1-1.1-0.7-4.5-0.3c-0.6,0.1-1.1,0.4-1.4,0.8c-0.2,0.2-0.4,0.4-0.6,0.6c-1,0.8-2.2,1.1-3.5,1c-0.2,0-0.4,0-0.6,0
        c-0.3,0-0.7,0-1,0c0,0.2,0,0.4,0,0.5l0.1,0.2v0.2c0,0.9,0.1,1.8,0.4,2.6c0.2-0.4,0.3-0.8,0.4-1.2l0.2-0.8l0.8-0.2
        c0.9-0.2,1.8,0,2.5,0.6c0.7,0.6,1.1,1.6,1.1,2.5c0.5,0,1,0.2,1.5,0.5c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.2,0.1,0.3,0.2,0.4
        c0.2,0.2,0.5,0.4,0.9,0.5c1.2,0.4,2.6,0,3.5-1c0.3-0.4,0.5-0.8,0.6-1.2c0.1-0.3,0.2-0.6,0.3-0.8c0.3-0.6,0.7-1.2,1.1-1.7
        L194.1,134.1z M161.7,104.1c1.1-1.5,2.8-1.6,4-1.6c0.5,0,1,0,1.5-0.1c0.5-0.1,1-0.3,1.5-0.6c0.5-0.3,1-0.5,1.6-0.6
        c1.1-0.3,2.2-0.3,3.4-0.2l0.7,0c0.8,0,1.6,0.1,2.3,0.2c1.5,0.2,3.1,0.3,4.7,0.2c0.7-0.1,1.4-0.4,1.9-0.8c0.2-0.1,0.4-0.3,0.5-0.5
        c0.1-0.2,0.3-0.4,0.4-0.6c1.4-1.6,4.4-2.3,4.7-2.4c0.8-0.2,1.5-0.4,2.3-0.7c1-0.3,1.9-0.6,2.9-0.8c0.9-0.2,1.7-0.4,2.5-0.8
        c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.2,0.7-0.3,1-0.4c0.2-0.1,0.5-0.2,0.8-0.2c0.2-0.1,0.5-0.2,0.8-0.2c-0.2-0.7-0.4-1.4-0.8-2
        c-0.3-0.5-0.5-1-0.6-1.5c-0.2-0.9,0-1.9,0.7-2.7c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0-0.4-0.1-0.5c-0.4-0.4-0.9-0.6-1.5-0.7
        c-0.2,0-0.4,0-0.6,0c-0.2,0.1-0.5,0.2-0.7,0.4c-0.5,0.4-1.1,0.7-1.7,0.8c-1.2,0.1-2.4-0.1-3.5-0.7c-0.7-0.4-1.4-0.9-2.1-1.4
        c-0.3-0.2-0.6-0.4-0.9-0.7c-0.4-0.2-1-0.3-1.5-0.3h-0.3c-2.2,0-5.9,0-6.6-3.3c-0.1-0.5-0.4-1-0.8-1.3l-0.2-0.1
        c-0.7-0.6-1.5-1-2.4-1.4l-0.9-0.2l-0.1-0.6c-0.5-0.2-0.9-0.4-1.4-0.5c-0.2-0.1-0.5-0.1-0.6-0.2c-0.9-0.4-1.6-0.9-2.3-1.6
        c-0.3-0.3-0.5-0.5-0.8-0.7c-1.8-1.3-4.1-1.7-6.2-1.2c-2.3,0.6-4.7,0.1-6.6-1.3c-0.5-0.3-0.9-0.6-1.3-0.9c-1.2-0.9-2.1-1.5-3.5-1.4
        c-1.6,0.1-3,0.2-4.2-1.7c-0.5-0.6-1.1-1-1.8-1.2c-0.2-0.1-0.5-0.2-0.7-0.3c-2.1-1.1-4-2.4-5.8-3.8c-1.1-0.7-1.7-2-1.5-3.3
        c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4,0-0.6,0c-0.8,0.2-1.6,0-2.3-0.3c-0.6-0.4-1-1-1.2-1.6l-0.1-0.2
        c0-0.1-0.1-0.2-0.2-0.3c-0.5-0.3-1-0.4-1.6-0.4c-0.4,0-0.8-0.1-1.2,0c-0.4,0-0.8,0-1.2,0c-0.5-0.1-0.9-0.1-1.3-0.2
        c-0.4-0.1-0.8-0.2-1.3-0.2c-1.5-0.2-2.9-0.7-4.1-1.7l-0.5-0.4c-0.3-0.2-0.6-0.5-1-0.7c-0.8-1.1-2.2-1.5-3.4-1
        c-0.9,0.2-1.9,0.3-2.8,0.1c-1.2-0.4-2.3-1.2-3.1-2.2l-0.2-0.2c-0.2-0.2-0.4-0.5-0.6-0.7c-0.5-0.7-1.2-1.3-2.1-1.6
        c-1.2-0.3-2.4-0.3-3.5,0c-0.4,0.1-0.8,0.2-1.2,0.3c-0.7,0.2-1.3,0.4-2,0.5c-0.5,0-0.9,0-1.4-0.2c-0.1,0-0.3-0.1-0.4-0.1
        c-0.2,0-0.4,0-0.7,0c-0.4,0-0.8,0-1.2-0.1c-1.7-0.4-3.3-1.1-4.7-2.2l-0.8-0.5c-0.9-0.6-1.8-1.2-2.7-1.8s-1.9-1.3-2.8-1.9
        c-0.4-0.3-0.9-0.5-1.3-0.8c-1-0.6-2-1.2-2.9-1.8c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-0.3-0.6-0.5-0.9-0.7c-0.5-0.1-1-0.1-1.5,0.2
        c-0.7,0.3-1.5,0.4-2.2,0.4c-1.1-0.1-2.2-0.4-3.2-1c-0.3-0.2-0.6-0.4-0.8-0.6c-0.4-0.3-0.8-0.5-1.2-0.7c-0.5,0-1,0.1-1.4,0.3
        c-0.6,0.2-1.3,0.4-2,0.4c-1.5-0.1-2.9-0.5-4.2-1.2c-0.3-0.1-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-1.1-0.2-2.1-0.9-2.7-1.9
        c-0.4-0.8-0.6-1.6-0.6-2.4c-1.3,0.2-2.5-0.4-3.2-1.5c-0.2-0.2-0.3-0.5-0.5-0.7c-0.6-0.9-0.6-0.9-1.4-0.7c-0.6,0.2-1.2,0.5-1.8,0.8
        c-1.1,0.7-2.3,1.1-3.5,1.1c-0.2,0-0.6,0-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c0.1,0.2,0.1,0.3,0.1,0.5c0.2,2.6-1.3,3.4-2.3,3.6
        c-0.4,0.1-0.8,0.1-1.2,0c-0.1,0-0.2,0-0.4,0c-2,0-3.9-0.2-5.9-0.6c-0.4-0.1-0.9-0.2-1.4-0.3c-1.6-0.4-3.1-0.7-4.2,0
        c-0.5,0.4-0.9,0.9-1.2,1.5l0.1,0c0.2,0.1,0.4,0.3,0.5,0.4c0.4,0.4,0.6,0.8,0.9,1.2c0.6,1.2,0.6,2.5,0.2,3.8c-0.2,0.6-0.6,1.2-1,1.6
        c-0.1,0.2-0.3,0.4-0.5,0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6c0.7-0.4,1.7-0.3,2.2,0.4c0.4,0.5,0.6,1.3-0.3,2.8
        c-0.1,0.2-0.2,0.3-0.3,0.5c-0.5,0.7-0.5,0.8-0.5,1c0,0.1,0.1,0.2,0.2,0.3c0.5,0.7,0.7,1.6,0.5,2.5c-0.1,0.6-0.6,1.1-1.2,1.2
        l-0.3,0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.6-0.5,0.9c-0.4,0.4-0.8,0.8-1.3,1c-0.2,0.1-0.3,0.2-0.5,0.3
        c-0.4,0.4-0.5,0.9-0.5,1.4c1.4-0.5,2.9-0.7,4.3-0.7l0.5,0c3.5-0.3,4.4-0.1,5,0.3c1,0.8,1.4,2.1,1,3.4c0,0.2-0.1,0.4-0.1,0.5
        C43,56,43.2,56,43.4,56c0.5,0,0.9,0,1.3-0.2c1.2-0.5,2.6-0.3,3.5,0.6c0.3,0.3,0.6,0.6,0.9,0.9c1.1,1.1,1.7,1.7,3.2,1.8
        c0.8,0,1.6-0.1,2.3-0.4c1.5-0.5,3.1-0.6,4.5-0.2c0.7,0.3,1.3,0.9,1.5,1.6c0,0.1,0.1,0.3,0.2,0.4c0.3,0.2,0.6,0.2,0.9,0.2
        c0.2,0,0.4,0,0.6,0l1.2,0l0.1,1.2c0.1,0.8,0,1.7-0.1,2.5c-0.1,0.4-0.3,0.8-0.5,1.1c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0,0,0,0.1
        c0.2,0,0.3,0,0.5,0.1c0.7,0.1,1.4,0.4,2,0.9c1,0.8,1.6,2,1.3,3.3c-0.5,2.4-4.1,3.4-6.4,4.1c-0.4,0.1-0.7,0.2-0.9,0.3L58.8,75
        c-0.4,0.1-0.8,0.3-1.2,0.6c0,0-0.1,0.2-0.2,0.4c-0.2,0.5-0.5,1-0.9,1.4c-0.4,0.4-0.9,0.7-1.4,0.9c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.9,0.5-0.9,0.6-0.9,1.8c0,0.4-0.1,0.9-0.1,1.5c-0.6,3.5-1.2,7.8-5.2,9.4c-0.4,0.2-0.7,0.3-1,0.4c-0.3,0.1-0.6,0.2-0.9,0.4
        c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.3,0,0.6,0.1,0.9c0.1,0.9,0.1,1.7-0.1,2.6c-0.1,0.5-0.4,1.1-0.7,1.5c-0.2,0.3-0.3,0.6-0.4,0.9
        l-0.3,0.9l-0.9,0c-1.8,0.1-3.6,0-5.4,0c-0.3,0-0.6,0-0.7,0c0.1,0.3,0.2,0.7,0.4,1l0.2,0.5c0.5,1.4,0.9,2.8,1.2,4.2
        c0.2,0.6,0.4,1.2,0.7,1.7c0.4,0.7,0.7,1.5,0.9,2.3c0.8,3.9-2.4,5.6-4.8,6.8c-0.5,0.2-0.9,0.5-1.4,0.7c-2,1.2-2.2,2.7-1,5.9
        c0.1,0.3,0.2,0.5,0.4,0.7c0.6,0.9,0.9,1.9,0.7,3c-0.3,1-1.4,1.7-3.2,2.7c-0.5,0.3-1,0.6-1.2,0.8c-1.2,1-2.5,1.8-3.9,2.5
        c-1.4,0.6-1.4,0.7-1.4,2.5c0.1,1.4-0.2,2.9-0.6,4.2c0.4,0.3,0.8,0.6,1.3,0.7c0.5,0,1.1,0,1.6-0.1c1.2-0.2,2.5-0.1,3.7,0.3
        c1.1,0.7,1.8,1.8,1.8,3.1c0,0.3,0.1,0.7,0.2,1c0.3,0.7,0.8,1.3,1.2,1.9c0.4,0.5,0.7,1,1.1,1.5c0.6,0.9,2.1,3.3,0.8,5.2
        c-0.3,0.4-0.6,0.6-1,0.8c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.3,0,0.4c-0.1,0.8,0.3,1.6,0.9,2c0.8,0.9,1.3,1.9,1.4,3.1
        c0.1,0.4,0.2,0.9,0.4,1.3c0.6,1.4,1.5,2.6,2.5,3.6c1,1,2.4,2.4,3.6,2.6c1.2,0.3,2.5,0.1,3.5-0.6c0.6-0.6,1-1.2,1.3-1.9l0.5-0.8
        c0.6-1,1.2-2.1,3.1-2.1l0,0c1.2,0,2.4,0.2,3.6,0.5l0.4,0.1c1.4,0.4,4,1.3,5.2,0.6c0.2-0.2,0.5-0.4,0.6-0.7c0.4-0.7,0.9-1.2,1.6-1.6
        c1.4-0.8,3.2-0.9,5.6-0.5c0.9,0.2,1.7,0.4,2.5,0.6c0.5,0.2,1.1,0.3,1.6,0.4l0.5,0.1c1,0.2,2,0.5,2.8,1.1c0.4,0.1,0.9,0.3,1.3,0.5
        c0.3,0.2,0.5,0.3,0.9,0.3c0.3,0,0.7,0,1.1-0.1c1.2-0.1,2.4-0.1,3.5,0.1c1,0.3,2,0.8,2.8,1.5c0.4,0.3,0.8,0.6,1.2,0.8
        c1.2,0.5,2.5,0.6,3.7,0.3c0.3-0.1,0.5-0.2,0.7-0.3c0.6-0.3,1.3-0.5,2-0.5c1.4,0.1,2.8,0.6,3.9,1.4l0.3,0.2c0.3,0.2,0.6,0.4,0.8,0.6
        c0.8,0.6,1,0.7,1.5,0.5c0.9-0.4,1.7-1.1,2.2-2l0.6-0.8c0.8-1.2,1.9-2.2,3-3c0.4-0.3,0.8-0.6,1.3-0.8c0.8-0.5,1.5-0.9,2.2-1.5
        c0.3-0.2,0.7-0.5,1-0.8c1.4-1.1,3.2-2.6,5.4-2.2c1.1,0.1,2.2,0.7,2.9,1.6l0.4,0.4c0.3,0.3,0.8,0.5,1.3,0.4c0.6,0,1.2,0,1.8-0.1
        l0.5,0c0,0,0.1,0,0.1,0c0,0,0-0.1-0.1-0.1c-0.2-0.3-0.4-0.6-0.5-0.8c-0.9-2.2,1.4-3.5,2.8-4.4c0.4-0.2,0.7-0.4,1.1-0.7
        c1.7-1.8,3.1-3.7,4.2-5.9l0.6-1c0.4-0.9,1.1-1.5,2-1.8c0.6-0.1,1.2-0.2,1.7-0.2c0.3,0,0.6,0,0.9,0c0.7-0.2,1.3-0.5,2-0.9
        c0.4-0.2,0.8-0.4,1.2-0.6c0.5-0.2,1-0.4,1.4-0.5c0.5-0.1,1-0.3,1.5-0.6c0-0.4-1-1.3-1.5-1.7c-0.3-0.3-0.6-0.5-0.9-0.8
        c-0.6-0.7-1.1-1.6-1.2-2.6l-0.1-0.3c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.2-0.2-0.5-0.3-0.8c-1.7-4-1.1-8.6,1.7-11.8
        c1.8-2.1,4.4-3,6.8-3.7l1-0.3c1.8-0.6,3.2-2.4,4.5-4.2c0.4-0.5,0.8-1,1.1-1.5c0.1-0.2,0.3-0.4,0.4-0.6c0.4-0.8,1-1.4,1.8-1.8
        c0.6-0.3,1.4-0.4,2.1-0.2c0.1,0,0.3,0,0.4,0c1.4,0.1,2,0,2.6-0.6l0.1-0.1l0-0.1c-0.2-0.6-0.2-1.2-0.2-1.8c0.1-1,0.5-2,1.2-2.7
        L161.7,104.1z"
                />
                <image
                    x={currentPlacePosition.x}
                    y={currentPlacePosition.y}
                    fillOpacity={!!currentCountry ? '1' : '0'}
                    width="35"
                    height="35"
                    xlinkHref="img/place.svg"
                />
            </svg>

            <div className="main-map__list">
                <h4 className="main-map__list__title">NPL focus</h4>
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Spain' })}
                    onClick={(): void => setCurrentCountry('Spain')}
                >
                    Spain<i>,</i>
                </span>{' '}
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Portugal' })}
                    onClick={(): void => setCurrentCountry('Portugal')}
                >
                    Portugal<i>,</i>
                </span>{' '}
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Italy' })}
                    onClick={(): void => setCurrentCountry('Italy')}
                >
                    Italy<i>,</i>
                </span>{' '}
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Greece' })}
                    onClick={(): void => setCurrentCountry('Greece')}
                >
                    Greece<i>,</i>
                </span>{' '}
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Cyprus' })}
                    onClick={(): void => setCurrentCountry('Cyprus')}
                >
                    Cyprus<i>,</i>
                </span>{' '}
                <span
                    className={cx('main-map__country', { selected: currentCountry === 'Balkans' })}
                    onClick={(): void => setCurrentCountry('Balkans')}
                >
                    Balkans
                </span>
                <h4 className="main-map__list__bottom-title">
                    Special Situations & Public market on stress
                </h4>
                <p className="main-map__list__bottom-description">
                    The whole Globe and All industries
                </p>
            </div>
        </>
    );
};

export default WorldMap;
