import React from 'react';
import Modal from 'components/modal';
import store from 'store';
import cx from 'classnames';
import { useObserver } from 'mobx-react';

const ContactUs: React.FC = () => {
    return useObserver(() => (
        <>
            <Modal
                showed={store.ui.contactUsModalShowed}
                onClose={(): void => store.ui.toggleContactUsModal()}
                whiteBg
            >
                <div>
                    If you require any further information, <br /> contact us by e-mail
                </div>
                <a href="mailto: info@opportunity.global">info@opportunity.global</a>
            </Modal>
            <div className="text-center">
                <button
                    type="button"
                    className="rb btn btn-default btn-shadow"
                    onClick={(): void => store.ui.toggleContactUsModal()}
                >
                    Contact Us
                </button>
            </div>
        </>
    ));
};

export default ContactUs;
