import React, { useEffect } from 'react';
import s from './modal.module.scss';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.min';
import { slideToBottom } from 'animations';
import { Transition } from 'react-spring/renderprops.cjs';
import { useSpring, animated } from 'react-spring/web.cjs';
import ClickOutHandler from 'react-onclickout';
import cx from 'classnames';
import { detect } from 'detect-browser';

interface ModalProps extends React.HTMLProps<HTMLDivElement> {
    showed: boolean;
    onClose: () => void;
    whiteBg?: boolean;
}

const browserInfo = detect();

const Modal: React.FC<ModalProps> = ({ showed, children, onClose, whiteBg }) => {
    useEffect((): void => {
        const el = browserInfo?.name !== 'ios' ? document.querySelector('.modal') : null;
        if (el) {
            if (showed) disableBodyScroll(el, { reserveScrollBarGap: true });
            else enableBodyScroll(el);
        }
    }, [showed]);

    const animatedWindowProps = useSpring({
        from: {
            opacity: showed ? 0 : 1
        },
        to: { opacity: showed ? 1 : 0 }
    });
    return (
        <div className="modal">
            <Transition
                items={showed}
                from={slideToBottom.from}
                enter={slideToBottom.enter}
                leave={slideToBottom.leave}
            >
                {(show: boolean) =>
                    show &&
                    ((props): React.ReactChild => (
                        <animated.div className={s.modal} style={animatedWindowProps} id="s-modal">
                            <ClickOutHandler onClickOut={onClose}>
                                <div
                                    className={cx({
                                        [s.modalWindow]: true,
                                        [s.modalWhite]: whiteBg
                                    })}
                                    style={props}
                                >
                                    <button
                                        type="button"
                                        className={s.modalClose}
                                        onClick={onClose}
                                    />
                                    {children}
                                </div>
                            </ClickOutHandler>
                        </animated.div>
                    ))
                }
            </Transition>
        </div>
    );
};

export default Modal;
