import React, { useState, useRef } from 'react';
import Modal from 'components/modal';
import store from 'store';
import { useObserver } from 'mobx-react';

import Slider, { Settings } from 'react-slick';
import cx from 'classnames';
import s from './gallery-modal.module.scss';

interface ArrowProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
    type: 'prev' | 'next';
}

const Arrow: React.FC<ArrowProps> = ({ type, onClick }) => {
    return (
        <button
            type="button"
            className={cx({
                [s.thumbArrowPrev]: type === 'prev',
                [s.thumbArrowNext]: type === 'next'
            })}
            onClick={onClick}
        />
    );
};

const GalleryModal: React.FC = () => {
    const speed = 500;

    const settings: Settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        adaptiveHeight: true,
        arrows: true,
        nextArrow: <Arrow type="next" />,
        prevArrow: <Arrow type="prev" />,
        speed,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange(current, next): void {
            setTimeout((): void => {
                store.ui.currentSliderImageIdx = next;
            }, speed);
        }
    };


    const thumbnailsSettings: Settings = {
        infinite: true,
        arrows: false,
        slidesToShow: store.ui.galleryImages.length,
        slidesToScroll: 2
    };

    const [nav1, setNav1] = useState<Slider | undefined>(undefined);
    const [nav2, setNav2] = useState<Slider | undefined>(undefined);

    const slider1 = useRef<Slider>(null);
    const slider2 = useRef<Slider>(null);

    const initChain = (): void => {
        setTimeout((): void => {
            slider1.current && setNav1(slider1.current);
            slider2.current && setNav2(slider2.current);
        }, 100);
    };

    return useObserver(() => (
        <Modal
            showed={store.ui.galleryModalShowed}
            onClose={(): void => store.ui.toggleGalleryModal(false, null)}
        >
            <Slider
                className={cx({ [s.slider]: true, 'gallery-slider': true })}
                {...settings}
                asNavFor={nav2}
                ref={slider1}
                onInit={initChain}
                initialSlide={store.ui.currentSliderImageIdx}
            >
                {store.ui.galleryImages.map(img => (
                    <img
                        key={`slider-${img.url}`}
                        className={s.sliderImage}
                        src={img.url}
                        alt={img.name}
                    />
                ))}
            </Slider>
            <Slider
                className={cx({ [s.thumbnail]: true, 'gallery-slider-thumb': true })}
                {...thumbnailsSettings}
                asNavFor={nav1}
                ref={slider2}
                initialSlide={store.ui.currentSliderImageIdx}
            >
                {store.ui.galleryImages.map((img, idx) => (
                    <div
                        onClick={(): void => {
                            if (slider1.current) {
                                slider1.current.slickGoTo(idx);
                            }
                        }}
                    >
                        <div
                            key={`thumb-${img.url}`}
                            alt={img.name}
                            className={cx(s.thumbImage, {
                                [s.thumbImageActive]: idx === store.ui.currentSliderImageIdx
                            })}
                            style={{ backgroundImage: `url(${img.url})` }}
                        />
                    </div>
                ))}
            </Slider>
        </Modal>
    ));
};

export default GalleryModal;
