import { action, computed, observable, toJS } from 'mobx';

interface Image {
    url: string;
    name: string;
    description: string;
    position: ('placement' | 'facade')[];
}

class UiStore {
    @observable public galleryModalShowed = false;

    @observable public contactUsModalShowed = false;

    @observable public images: Image[] = [
        // {
        //     url: 'img/top-slider/1.jpg',
        //     name: '',
        //     description: 'Moscow',
        //     position: ['placement']
        // },
        // {
        //     url: 'img/top-slider/2.jpg',
        //     name: '',
        //     description: 'Moscow',
        //     position: ['placement']
        // },
        // {
        //     url: 'img/top-slider/6.jpg',
        //     name: '',
        //     description: 'Moscow',
        //     position: ['placement']
        // },
        {
            url: 'img/top-slider/4.jpg',
            name: '',
            description: 'Moscow',
            position: ['placement']
        },
        {
            url: 'img/top-slider/5.jpg',
            name: '',
            description: 'Moscow',
            position: ['placement']
        },
        {
            url: 'img/top-slider/3.jpg',
            name: '',
            description: 'Moscow',
            position: ['placement']
        },
        {
            url: 'img/slider/4-1.jpg',
            name: 'Ulitsa Butlerova, 17',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/4-2.jpg',
            name: 'Ulitsa Butlerova, 17',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/6-1.jpg',
            name: 'Presnenskaya Naberezhnaya, 12',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/6-2.jpg',
            name: 'Presnenskaya Naberezhnaya, 12',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/2-1.jpg',
            name: 'Novoryazanskaya Ulitsa, 8',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/2-2.jpg',
            name: 'Novoryazanskaya Ulitsa, 8',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/1-1.jpg',
            name: 'Novoryazanskaya Ulitsa, 8AC1',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/1-2.jpg',
            name: 'Novoryazanskaya Ulitsa, 8AC1',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/1-3.jpg',
            name: 'Novoryazanskaya Ulitsa, 8AC1',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/3-1.jpg',
            name: 'Prospekt Mira, 105C1',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/3-2.jpg',
            name: 'Prospekt Mira, 105C1',
            description: 'Moscow',
            position: ['facade']
        },
        {
            url: 'img/slider/5-1.jpg',
            name: "Zubovskiy Bul'var, 17",
            description: 'Moscow',
            position: ['facade']
        }
    ];

    @observable public currentSliderImageIdx = 0;

    @observable public galleryImages: Image[] = this.images;

    @computed public get facadeImages(): Image[] {
        return Object.entries(toJS(this.images))
            .filter(img => img[1].position.includes('facade'))
            .map(img => img[1]);
    }

    @computed public get placementImages(): Image[] {
        return Object.entries(toJS(this.images))
            .filter(img => img[1].position.includes('placement'))
            .map(img => img[1]);
    }

    @action('Toggle contact us modal') public toggleContactUsModal() {
        this.contactUsModalShowed = !this.contactUsModalShowed;
    }

    @action('Toggle gallery modal') public toggleGalleryModal(
        status: boolean | null,
        type: 'facade' | 'placement' | null,
        imgUrl?: string
    ): void {
        const state = status !== undefined && status !== null ? status : !this.galleryModalShowed;
        if (type) {
            this.galleryImages = type === 'facade' ? this.facadeImages : this.placementImages;
        }
        if (imgUrl) {
            for (const img of this.galleryImages) {
                if (img.url === imgUrl) {
                    this.currentSliderImageIdx = this.galleryImages.indexOf(img);
                }
            }
        } else this.currentSliderImageIdx = 0;

        this.galleryModalShowed = state;
    }
}

export default new UiStore();
